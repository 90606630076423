.magnifier {
  position: relative;
  display: inline-block;
  line-height: 0;
  z-index: 1000;
}

.magnifier-image {
  cursor: pointer;
}

.magnifying-glass {
  position: absolute !important;
  // top: 0 !important;
  // left: 0 !important;
  z-index: 10010 !important;
  background: #e5e5e5 no-repeat; // Use opaque background to hide main image when using an offset
  border: solid #ebebeb;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.3);
  opacity: 0; // Needed for transition
  transform: translateX(-250px);
  transition: opacity 0.3s;
  pointer-events: none;
  border-radius: 5px;

  &.circle {
    border-radius: 50%;
  }

  &.visible {
    opacity: 1; // Needed for transition
  }
}
