.bigPcture {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  // padding: 20px;
  transition: all 0.3s;
  z-index: 1000;
  // -webkit-animation: slide-in-blurred-top 0.6s cubic-bezier(0.23, 1, 0.32, 1) both;
  // animation: slide-in-blurred-top 0.6s cubic-bezier(0.23, 1, 0.32, 1) both;
  .realBox {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;

    .closeButton {
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
    }

    .tag {
      position: absolute;
      top: 20px;
      left: 20px;
      background: rgba(0, 0, 0, 1);
      padding: 18px;
      // z-index: 10;
      border-radius: 5px;

      .tagRow {
        color: white;
        display: flex;
        align-items: center;

        span:nth-of-type(1) {
          min-width: 36px;
          text-align: left;
        }

        .warp {
          max-width: 150px;
          word-break: normal;
          width: auto;
          display: block;
          white-space: pre-wrap;
          word-wrap: break-word;
          overflow: hidden;
        }
      }
    }

    .showContent {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      // background-color: red;
    }
  }
}
.withSwiperWrapper {
  .swiper-wrapper {
    display: flex;
    align-items: center;
  }
}
.maskBigPctureImg {
  max-width: 22px;
  max-height: 44px;
  z-index: 1000;
  position: absolute;
  top: 0;
  right: 0;
  height: 4.5vw !important;
  width: 2.5vw !important;
  border-radius: 5px;
  cursor: pointer;
  margin: {
    top: 16px;
    right: 16px;
  }

  img {
    position: absolute;
    top: 0;
    right: 0;
    height: 22px !important;
    width: 22px !important;
    border-radius: 5px;
    cursor: pointer;
    margin: {
      top: 16px;
      right: 16px;
    }
  }
}

.slidePadding {
  // width: 100%;
  padding: {
    // left: 40px;
    // right: 40px;
  }
  border-radius: 5px;

  .slideContent {
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  // width: 80vw !important;
  .slidePaddingItem {
    display: inline-block;
    // height: 100%;
    // height: 100%;
    // display: flex;
    // transform-style: preserve-3d;
    // align-items: center;
    // justify-content: center;
    img {
      // max-height: 100% !important;
      max-width: 100% !important;
      width: auto;
      height: auto;
      object-fit: contain;
      border-radius: 5px;
    }
  }
}

@-webkit-keyframes slide-in-blurred-top {
  0% {
    -webkit-transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
    transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) scaleY(1) scaleX(1);
    transform: translateY(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}

@keyframes slide-in-blurred-top {
  0% {
    -webkit-transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
    transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) scaleY(1) scaleX(1);
    transform: translateY(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}

// .swiper-slide {
//   // width: 100vh !important;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   width: 100vh !important;
//   height: 100vh !important;
//   transform: scale(0.8) !important;
//   transition: all 0.3s;
//   .superImageimg {
//     max-height: 95% !important;
//     max-width: 95% !important;
//   }
// }

// .swiper-slide-prev {
//   transform: scale(0.8) !important ;
//   // transform-style: preserve-3d;
//   transition: all 0.3s;
//   // .superImageimg {
//   //   height: auto !important;
//   //   width: auto !important;
//   // }
//   // animation: slide-rotate-ver-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
//   // linear-gradient(to left,rgba(0,0,0,.5),rgba(0,0,0,0))
// }

// .swiper-slide-next {
//   transform: scale(0.8) !important;
//   transition: all 0.3s;
//   // transform-style: preserve-3d;
//   // animation: slide-rotate-ver-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
// }

// .swiper-slide-active {
//   transform: scale(1) !important;
//   transition: all 0.3s;
// }

@keyframes slide-rotate-ver-right {
  0% {
    -webkit-transform: translateX(0) rotateY(0) scale(0.8);
    transform: translateX(-150px) translate3d(0) rotateY(0) scale(0.8);
  }
  100% {
    -webkit-transform: translateX(-150px) rotateY(-60deg) scale(0.8);
    transform: translateX(-150px) translate3d(0px, 0px, -100px) rotateX(0deg) rotateY(50deg) scale(0.8);
  }
}

@keyframes slide-rotate-ver-left {
  0% {
    -webkit-transform: translateX(0) rotateY(0) scale(0.8);
    transform: translateX(-150px) translate3d(0) rotateY(0) scale(0.8);
  }
  100% {
    -webkit-transform: translateX(150px) rotateY(-60deg) scale(0.8);
    transform: translateX(150px) translate3d(0px, 0px, -100px) rotateX(0deg) rotateY(50deg) scale(0.8);
  }
}

.Y {
  position: absolute;
  left: 50%;
  bottom: -40px;
  z-index: 2000;
  transform: translateX(-50%);

  button {
    color: white !important;
    border-color: white !important;
  }
}
.horizontal_Y {
  position: absolute;
  right: 70px;
  top: 20px;
  z-index: 2000;
  // transform: translateX(-50%);
  color: white !important;
  border-color: white !important;
}
.swiperY_img {
  img {
    transform: scale(0.9) !important;
  }
}
.ipad {
  // // 兼容ipad
  // @media (min-width: 1355px) {
  //   .swiper-slide-active {
  //     transform: scale(1.3) !important;
  //     transition: all 0.3s;
  //   }
  //   .swiper-slide-prev {
  //     transform: scale(1) translateX(-50%) !important ;
  //     transition: all 0.3s;
  //   }

  //   .swiper-slide-next {
  //     transform: scale(1) translateX(50%) !important;
  //     transition: all 0.3s;
  //   }
  // }
}

// // // 页面大于 799px时
// @media (min-height: 799px) {
//   .swiper-slide-active {
//     transform: scale(1.1) !important;
//     transition: all 0.3s;
//   }
//   .swiper-slide-prev {
//     transform: scale(0.8) translateX(-50%) !important ;
//     transition: all 0.3s;
//   }
//   .swiper-slide-next {
//     transform: scale(0.8) translateX(50%) !important;
//     transition: all 0.3s;
//   }
// }
// @media (min-height: 1125px) {
//   .swiper-slide-active {
//     transform: scale(1.4) !important;
//     transition: all 0.3s;
//   }
//   .swiper-slide-prev {
//     transform: scale(0.8) translateX(-50%) !important ;
//     transition: all 0.3s;
//   }
//   .swiper-slide-next {
//     transform: scale(0.8) translateX(50%) !important;
//     transition: all 0.3s;
//   }
// }

// @media (min-height: 1349px) {
//   .swiper-slide-active {
//     transform: scale(1.6) !important;
//     transition: all 0.3s;
//   }
//   .swiper-slide-prev {
//     transform: scale(0.8) translateX(-50%) !important ;
//     transition: all 0.3s;
//   }
//   .swiper-slide-next {
//     transform: scale(0.8) translateX(50%) !important;
//     transition: all 0.3s;
//   }
// }
.tagLength {
  margin: {
    top: 16px;
  }
  span {
    min-width: auto !important;
  }
}
.ownStyleLikeOperaction {
  width: 100%;
  display: flex;
  align-items: center;
  .label {
  }
  .ownStyleLike {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white !important;
    border: 1px solid white !important;
    margin: {
      top: 5px;
    }
    .like {
      margin: {
        top: 1px;
      }
      color: red;
    }
    .noLike {
      color: white;
    }
  }
}
