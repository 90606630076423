.switchCustomers {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: {
    left: 1.25rem;
  }
  img {
    object-fit: cover;
    // width: 123px;
    max-height: 1.6rem !important;
  }
  span {
    margin: {
      left: 1.1vw;
    }
    &:hover {
      cursor: pointer;
    }
  }
}
.menuItem {
  background: black !important;
  color: white;
  user-select: none;
  height: 4vh;
  max-height: 3rem;
}
.add {
  background: #404040;
  color: white;
  box-shadow: 0px 1px 4px 0px rgba(0, 21, 41, 0.12);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 5vh;
  max-height: 2rem;

  cursor: pointer;
  // width: 10vw;
}
.ant-col-14 {
  max-width: unset;
}
