.search-container {
  display: flex;
  align-items: center;
  position: absolute;
  left: 10px;
  bottom: 5px;
  z-index: 10;
  transform: scale(0.95);
  .list-layout-div{
    display: flex;
    align-items: center;
    margin-left: 10px;
    .layout-item{
      height: 47px;
      margin-right: 10px;

      .item-icon{
        height: 47px;
      }
    }
  }
}
@media screen and (max-height: 1420px) and (min-height: 820px) {
  .search-container {
    bottom: 6px !important;
  }
}

@media screen and (min-height: 620px) and (max-height: 820px) {
  .search-container {
    bottom: 2px !important;
  }
}

.search-modal_rows {
  display: flex;
  align-items: center;
  margin: {
    bottom: 10px;
  }
  .search-modal_rows-title {
    white-space: nowrap;
    min-width: 50px;
  }
}
.s {
  bottom: 50px !important;
}
.modalFooter {
  width: 100%;
  display: flex;
  align-items: center;
  // rgb(86, 86, 86)
  justify-content: space-between;
}
.rangePicker {
  position: absolute;
  left: 5.5vw;
  top: auto;
  bottom: 1.6rem;
  .ant-modal-content {
    .ant-modal-body {
      padding: {
        top: 0;
        bottom: 0;
      }
    }
  }

  // visibility: hidden;
}
.ant-picker-range-arrow {
  display: none !important;
}
.footers {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: {
    top: 5px;
    bottom: 5px;
  }
}
.ant-picker-range-separator {
  span {
    color: white;
  }
}
.ant-picker-cell-disabled {
  div .ant-picker-cell-inner {
    color: red !important;
  }
}
.rdrDateDisplayWrapper {
  display: none;
}
.rdrDefinedRangesWrapper {
  display: none;
}
.rangePickerDisplay {
  display: none !important;
}
.rdrDateRangePickerWrapper {
  width: 100%;
  background-color: #1f1f1f !important;
  .rdrCalendarWrapper {
    width: 100%;
    background-color: #1f1f1f !important;
    .rdrMonths {
      width: 100%;
      .rdrMonth {
        width: 100%;
      }
    }
    .rdrMonthAndYearPickers {
      color: white;
    }
    .rdrMonthAndYearPickers select {
      color: white;
    }
  }
}
.rdrDayNumber {
  span {
    color: white !important;
  }
}
.rdrDayPassive {
  .rdrDayNumber {
    span {
      color: gray !important;
    }
  }
}
.slide-in-fwd-center {
  -webkit-animation: slide-in-fwd-center 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-fwd-center 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
/* ----------------------------------------------
 * Generated by Animista on 2021-11-5 11:37:27
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-fwd-center
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-fwd-center {
  0% {
    -webkit-transform: translateZ(-1400px);
    transform: translateZ(-1400px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}
@keyframes slide-in-fwd-center {
  0% {
    -webkit-transform: translateZ(-1400px);
    transform: translateZ(-1400px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}
.rdrMonthName {
  display: none;
}
.rdrPprevButton {
  background-color: #1f1f1f !important;
  i {
    border-color: transparent white transparent transparent !important;
  }
}

.rdrNextButton {
  background-color: #1f1f1f !important;
  i {
    border-color: transparent transparent transparent white !important;
  }
}
// .rdrWeekDay {
//   flex-basis: calc(100% / 10) !important;
// }
// .rdrDay {
//   flex-basis: calc(100% / 10) !important;
// }
