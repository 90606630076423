.superImage {
  height: 100%;
  position: relative;
  transition: all 0.3s;

  .superImageimg {
    display: black;
    object-fit: cover;
    height: 100%;
    width: 100%;
    cursor: pointer;
  }
  .rightTop {
    position: absolute;
    top: 20px;
    right: 0px;
  }
  .leftTop {
    position: absolute;
    top: 35px;
    left: 20px;
  }
  .isWrapperImgDiv {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
