@mixin alignItemCenter {
  display: flex;
  align-items: center;
}
.header {
  width: 100vw;
  height: 48px;
  background: #181818;
  box-shadow: 0px 1px 4px 0px #0015291f;
  @include alignItemCenter();
  justify-content: space-between;
  color: white;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  overflow: hidden;
  min-height: 0px;
  transition: all 0.3s;
  .switchCustomers {
    @include alignItemCenter();
    //flex: 1;
    flex: 0.25;
    margin: {
      right: 30px;
    }
    justify-content: flex-start;
  }
  .headerGoods {
    @include alignItemCenter();
    width: 70vw;
    flex: 1;
    transition: all 0.3s;
    // margin: {
    //   right: 30px;
    // }
    padding: {
      right: 60px;
    }
    // overflow: hidden;
    // overflow-x: scroll;
  }
  .limitWidth {
    width: 350px;
  }
  .buttonOperation {
    @include alignItemCenter();
    //flex: 1;
    justify-content: flex-end;
    margin: {
      right: 20px;
    }
  }
  box-shadow: 0px 1px 4px 0px rgba(23, 28, 32, 0.12);
}
.activeLight {
  position: fixed;
  top: 5px;
  right: 5px;
  height: 3px;
  width: 3px;
  border-radius: 50%;
  // transform: scale(0.5);
  // background-color: red;
  // background-color: greenyellow;
}
// @media screen and (max-width: 900px) {
//   .header {
//     .switchCustomers {
//       flex: 1.7;
//       margin: {
//         right: 20px;
//       }
//     }
//   }
// }
