* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
img {
  user-select: none;
}
// .ant-tabs-nav-more {
//   display: none;
// }
