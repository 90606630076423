.text {
  font-weight: 400;
  cursor: pointer;
  transition: all 0.3s;
  white-space: nowrap;
}
.noActive {
  color: rgba($color: #fff, $alpha: 0.3);
}
.active {
  color: #ffffff;
}
