.app {
  position: absolute;
  background-color: black;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
}
body {
  overscroll-behavior: none;
}

*::selection {
  background-color: gray;
}

*::-webkit-scrollbar {
  width: 0 !important;
  height: 0 !important;
}

.router-wrapper {
  flex: 1;
}

.forward-enter {
  opacity: 0;
  transform: translateX(100%);
}

.forward-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: all 500ms;
}

.forward-exit {
  opacity: 1;
  transform: translateX(0);
}

.forward-exit-active {
  opacity: 0;
  transform: translateX(-100%);
  transition: all 500ms;
}

/* 路由后退时的入场/离场动画 */
.back-enter {
  opacity: 0;
  transform: translateX(-100%);
}

.back-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: all 500ms;
}

.back-exit {
  opacity: 1;
  transform: translateX(0);
}

.back-exit-active {
  opacity: 0;
  transform: translate(100%);
  transition: all 500ms;
}
.ant-picker-body {
  background-color: black;
}
.ant-picker-header {
  background-color: black;
}
.ant-picker-footer {
  background-color: black;
}
.ant-tabs-nav::before {
  content: none !important;
}
.ant-picker-suffix {
  color: white;
}
.ant-select-arrow {
  color: white;
}
.ant-input-suffix {
  span {
    color: white;
  }
}
.ant-picker-next-icon {
  color: white !important;
}
.ant-picker-super-next-icon {
  color: white !important;
}
.ant-picker-super-prev-icon {
  color: white !important;
}
.ant-picker-prev-icon {
  color: white !important;
}
.ant-picker-cell-in-view .ant-picker-cell-inner {
  color: white !important;
}

.ant-picker-cell-inner {
  color: gray;
}
.ant-spin {
  color: white;
}
// .ant-picker-cell-selected {
//   background-color: #1f1f1f;
// }

.ant-tabs-tab-btn {
  &:hover {
    color: rgba(255, 255, 255, 0.6);
  }
}
.ant-pagination-item {
  &:hover {
    a {
      color: white !important;
    }
  }
}
.ant-pagination-item-container {
  &:hover {
    color: white !important;
  }
}

.anticon-double-right {
  color: white !important;
}
.anticon-double-left {
  color: white !important;
}
// #1890ff
.ant-tag-checkable {
  color: color;
  &:hover {
    color: white !important;
  }
}
.ant-tag-checkable-checked {
  color: black;
  background-color: white !important;
  &:hover {
    color: black !important;
  }
}
.ant-btn[disabled] {
  color: rgb(86, 86, 86) !important;
  border: rgb(86, 86, 86) !important;
}
.ant-switch {
  background-color: rgb(86, 86, 86) !important;
}
.ant-switch-checked {
  background-color: white !important;
  span {
    color: black;
  }
}
.ant-tag {
  background-color: black;
  margin-right: 0;
  border: none;
  span {
    svg {
      color: white;
    }
  }
}
