.form {
  display: flex;
  justify-content: center;
  .left {
    // width: 60%;
  }
  .right {
    // width: 100%;
    display: flex;
    flex-direction: column;
    margin: {
      left: 32px;
    }
    .backup {
      flex: 1;
      .ant-form-item-control-input {
        height: 100%;
        .ant-form-item-control-input-content {
          height: 100%;
        }
      }
      .ant-form-item-control {
        flex: 1;
      }
    }
  }
}
.footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: {
    top: 20px;
  }

  .buttonOperation {
  }
}
.ant-modal-close-icon {
  color: white;
}
.userRequred {
  .ant-form-item-required::before {
    position: absolute;
    right: 0;
    top: -2px;
  }
}
